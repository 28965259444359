<template>
  <div class="px-5 px-sm-12 pb-2 pt-0">
    <!-- Receipt Details -->
    <div class="py-2">
      <div class="font-weight-medium">
        <v-row>
          <v-col cols="8">
            <p class="mb-0 text-decoration-underline">
              Description
            </p>
          </v-col>
          <v-col cols="4">
            <p class="mb-0 text-decoration-underline">
              Amount
            </p>
          </v-col>
        </v-row>
        <div class="header-spacer"></div>
      </div>
    </div>

    <div class="py-2">
      <v-row>
        <v-col cols="8" class="py-0">
          <p class="mb-2">
            After discount (Original price: {{ record.insurance_premium }})
          </p>
        </v-col>
        <v-col
          cols="4"
          class="py-0"
        >
          <p class="mb-2">
            {{ record.after_discount }}
          </p>
        </v-col>
      </v-row>
    </div>

    <template v-if="record.voucher_amount">
      <div class="py-2">
        <v-row>
          <v-col cols="8" class="py-0">
            <p class="mb-2">
              Voucher discount
            </p>
          </v-col>
          <v-col
            cols="4"
            class="py-0"
          >
            <p class="mb-2">
              ({{ record.voucher_amount }})
            </p>
          </v-col>
        </v-row>
      </div>
    </template>

    <template v-if="record.insurance_product.name === 'Motor insurance'">
      <div class="py-2">
        <v-row>
          <v-col
            v-if="record.pickup_location"
            cols="8"
            class="py-0"
          >
            <p class="mb-2">
              Road Tax ({{ record.delivery_charges != '$10.00' ? `Pick up Location: ${record.pickup_location.name === "Others" ? record.pickup_location_remarks : record.pickup_location.name}` : `Delivery Address: ${record.address_remarks}` }})
            </p>
          </v-col>
          <v-col
            v-else
            cols="8"
            class="py-0"
          >
            <p class="mb-2">
              Road Tax Price
            </p>
          </v-col>
          <v-col
            cols="4"
            class="py-0"
          >
            <p class="mb-2">
              {{ record.road_tax }}
            </p>
          </v-col>
        </v-row>
      </div>
      <template v-if="record.express_charges === '$10.00'">
        <div class="py-2">
          <v-row>
            <v-col cols="8" class="py-0">
              <p class="mb-2">
                Express Charges
              </p>
            </v-col>
            <v-col
              cols="4"
              class="py-0"
            >
              <p class="mb-2">
                {{ record.express_charges }}
              </p>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-if="record.delivery_charges === '$10.00'">
        <div class="py-2">
          <v-row>
            <v-col cols="8" class="py-0">
              <p class="mb-2">
                Delivery Charges
              </p>
            </v-col>
            <v-col
              cols="4"
              class="py-0"
            >
              <p class="mb-2">
                {{ record.delivery_charges }}
              </p>
            </v-col>
          </v-row>
        </div>
      </template>
    </template>

    <div class="py-2">
      <v-row>
        <v-col cols="8" class="py-0">
          <p class="mb-2">
            Additional Fees
          </p>
        </v-col>
        <v-col
          cols="4"
          class="py-0"
        >
          <p class="mb-2">
            {{ record.additional_fees }}
          </p>
        </v-col>
      </v-row>
    </div>

    <!-- Receipt Footer -->
    <div class="py-2">
      <!-- Issuer -->
      <v-row>
        <v-col
          cols="4"
          class="d-flex align-center"
        >
          <p class="mb-0 pr-2 font-weight-semibold">
            Issued By:
          </p>
          <p class="mb-0">
            {{ record.issuer.nickname }}
          </p>
        </v-col>

        <v-col
          cols="4"
          class="d-flex align-center justify-end"
        >
          <p class="mb-0 font-weight-medium">
            Total:
          </p>
        </v-col>

        <!-- Total -->
        <v-col
          cols="4"
          class="d-flex align-center"
        >
          <p class="mb-0 font-weight-bold total-text">
            {{ record.total }}
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
  .total-text {
    font-size: 16px !important;
  }
</style>
