<template>
  <div class="py-2 px-5">
    <v-row class="mt-2">
      <v-col
        cols="12"
        sm="3"
        class="py-0"
      >
        <p class="font-weight-semibold">
          OFFICE HOUR
        </p>
        <v-row>
          <v-col cols="6" sm="12" class="pb-0">
            <div>
              <p class="font-weight-bold mb-0">
                Monday - Thursday
              </p>
              <p>
                8.30am - 4.30pm
              </p>
            </div>
          </v-col>
          <v-col cols="6" sm="12" class="pb-0 pt-sm-0">
            <div>
              <p class="font-weight-bold mb-0">
                Lunchtime
              </p>
              <p class="mb-0">
                11.30am - 1.30pm
              </p>
              <p>
                (Central Mall, Beribi & Belait only)
              </p>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" sm="12" class="pt-0 py-sm-0">
            <div>
              <p class="font-weight-bold mb-0">
                Friday
              </p>
              <p class="mb-0">
                8.30am - 11.30am
              </p>
              <p>
                2.30pm - 4.30pm
              </p>
            </div>
          </v-col>
          <v-col cols="6" sm="12" class="pt-0">
            <div>
              <p class="font-weight-bold mb-0">
                Saturday
              </p>
              <p>
                8.30am - 11.30am
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="9"
        class="py-0"
      >
        <p class="font-weight-semibold">
          LOCATIONS
        </p>
        <v-row>
          <v-col cols="4">
            <p class="font-weight-bold">
              Brunei Muara
            </p>
            <div
              v-for="branch in branches.filter(b => b.district === 'Brunei Muara')"
              :key="branch.id"
            >
              <p class="mb-0">{{ branch.title }}</p>
              <p class="mb-0">{{ branch.first_line }}, {{ branch.second_line }}</p>
              <p>Phone: {{ branch.contact_number }}</p>
            </div>
          </v-col>
          <v-col cols="4">
            <p class="font-weight-bold">
              Tutong
            </p>
            <div
              v-for="branch in branches.filter(b => b.district === 'Tutong')"
              :key="branch.id"
            >
              <p class="mb-0">{{ branch.title }}</p>
              <p class="mb-0">{{ branch.first_line }}, {{ branch.second_line }}</p>
              <p>Phone: {{ branch.contact_number }}</p>
            </div>
          </v-col>
          <v-col cols="4">
            <p class="font-weight-bold">
              Belait
            </p>
            <div
              v-for="branch in branches.filter(b => b.district === 'Belait')"
              :key="branch.id"
            >
              <p class="mb-0">{{ branch.title }}</p>
              <p class="mb-0">{{ branch.first_line }}, {{ branch.second_line }}</p>
              <p>Phone: {{ branch.contact_number }}</p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {

  data: () => ({
    branches: [],
  }),
  mounted() {
    this.fetchBranches()
  },
  methods: {

    fetchBranches() {
      this.$store
        .dispatch('fetchBranches')
        .then(response => {
          this.branches = response.data.data.filter(b => b.first_line && b.second_line && b.contact_number)
        })
        .catch(() => {
          this.$router.push('/')
        })
    },
  }
}
</script>
